export const teams = [
  {
    id: 1,
    name: 'Satellite',
    avatar: '/assets/img/svg/team-avatars/satellite.svg'
  },
  {
    id: 2,
    name: 'Saturn',
    avatar: '/assets/img/svg/team-avatars/saturn.svg'
  },
  {
    id: 3,
    name: 'Monkey',
    avatar: '/assets/img/svg/team-avatars/monkey.svg'
  }
]

export const teamWeekDays = {
  weekId: 1,
  weekName: 'Week 1',
  weekStatus: 1,
  weekDays: [
    {
      id: 1,
      dayId: 1,
      dayName: 'Monday',
      dayNameShort: 'Mon',
      dayStatus: 2,
      cost: 0,
      bonus: 0
    },
    {
      id: 2,
      dayId: 2,
      dayName: 'Tuesday',
      dayNameShort: 'Tue',
      dayStatus: 1,
      cost: 0,
      bonus: 0
    },
    {
      id: 3,
      dayId: 3,
      dayName: 'Wednesday',
      dayNameShort: 'Wed',
      dayStatus: 1,
      cost: 0,
      bonus: 0
    },
    {
      id: 4,
      dayId: 4,
      dayName: 'Thursday',
      dayNameShort: 'Thu',
      dayStatus: 1,
      cost: 0,
      bonus: 0
    },
    {
      id: 5,
      dayId: 5,
      dayName: 'Friday',
      dayNameShort: 'Fri',
      dayStatus: 1,
      cost: 0,
      bonus: 0
    }
  ]
}

export const workAllocations = [
  {
    id: 1,
    userId: 1,
    userName: 'Panda Test1',
    workVolume: 0,
    status: 0,
    thumb: '/assets/img/svg/team-avatars/monkey.svg'
  },
  {
    id: 2,
    userId: 2,
    userName: 'Panda Test2',
    workVolume: 0,
    status: 0,
    thumb: '/assets/img/svg/team-avatars/monkey.svg'
  },
  {
    id: 3,
    userId: 3,
    userName: 'Panda Test3',
    workVolume: 0,
    status: 0,
    thumb: '/assets/img/svg/team-avatars/monkey.svg'
  },
  {
    id: 4,
    userId: 4,
    userName: 'Panda Test4',
    workVolume: 0,
    status: 0,
    thumb: '/assets/img/svg/team-avatars/monkey.svg'
  },
  {
    id: 5,
    userId: 5,
    userName: 'Panda Test5',
    workVolume: 0,
    status: 0,
    thumb: '/assets/img/svg/team-avatars/monkey.svg'
  },
  {
    id: 6,
    userId: 6,
    userName: 'Panda Test6',
    workVolume: 0,
    status: 0,
    thumb: '/assets/img/svg/team-avatars/monkey.svg'
  },
  {
    id: 7,
    userId: 7,
    userName: 'Panda Test7',
    workVolume: 0,
    status: 0,
    thumb: '/assets/img/svg/team-avatars/monkey.svg'
  },
  {
    id: 8,
    userId: 8,
    userName: 'Panda Test8',
    workVolume: 0,
    status: 0,
    thumb: '/assets/img/svg/team-avatars/monkey.svg'
  },
  {
    id: 9,
    userId: 9,
    userName: 'Panda Test9',
    workVolume: 0,
    status: 0,
    thumb: '/assets/img/svg/team-avatars/monkey.svg'
  },
  {
    id: 10,
    userId: 10,
    userName: 'Panda Test10',
    workVolume: 0,
    status: 0,
    thumb: '/assets/img/svg/team-avatars/monkey.svg'
  }
]

export const teamWeekDayControlModal = [
  {
    id: 1,
    title: 'Are you sure you want to move to {next_week}?',
    subtitle: '{current_week} will be closed, and you will proceed to {next_week} Monday.',
    img: '/assets/img/svg/controls/running_outline_info.svg'
  },
  {
    id: 2,
    title: 'Are you sure to complete current day?',
    subtitle: 'Current day will be automatically closed.',
    img: '/assets/img/svg/controls/jogging_outline_new.svg'
  },
  {
    id: 3,
    title: 'Are you sure to start the game?',
    subtitle: 'Game will be automatically completed in 90 seconds.',
    img: '/assets/img/svg/controls/startup_outline_new.svg'
  },
  {
    id: 4,
    title: 'Are you sure to finish the game?',
    subtitle: 'A new game will be available once the following weekday starts.',
    img: '/assets/img/svg/controls/startup_outline_new.svg'
  }
]

export const userRoles = [
  {
    id: 3,
    name: 'Team Leader'
  },
  {
    id: 4,
    name: 'Team Member'
  }
]

export const teamFields = [
  {
    key: 'avatar',
    label: 'Team Name',
    sortable: false,
    thClass: 'w-10',
    tdClass: 'align-middle'
  },
  {
    key: 'teamName',
    label: '',
    sortable: false,
    thClass: 'w-80',
    tdClass: 'align-middle'
  },
  {
    key: 'actions',
    label: 'Actions',
    thClass: 'w-5',
    tdClass: 'align-middle action-column'
  }
]

export const gameSettingsToggle = [
  {
    id: 1,
    title: 'Game Settings',
    icon: 'simple-icon-settings',
    order: 1
  },
  {
    id: 2,
    title: 'Game Financials',
    icon: 'simple-icon-notebook',
    order: 2
  }
]
