<template>
  <b-dropdown
    menu-class="a-shadow-sm dropdown-menu-xl base-entity-selection"
    no-caret
    toggle-class="px-2 py-1 base-entity-selection-toggle opacity-100"
    variant="empty"
    :disabled="disabled"
  >
    <template slot="button-content">
      <div v-if="loading">
        <p class="p-0 m-0 align-self-center">
          <b-badge variant="light" disabled>
            <span>{{ loadingPrompt }}</span>
          </b-badge>
        </p>
      </div>
      <div v-else>
        <div v-if="selected && selected.id" class="align-self-center d-flex">
          <b-avatar
            class="align-self-center no-border"
            size="25"
            :src="selected.avatar ? selected.avatar : ''"
          ></b-avatar>
          <p class="align-self-center mb-0 ml-2">{{ selected.name }}</p>
        </div>
      </div>
    </template>
    <b-dropdown-group>
      <div class="mx-2">
        <b-form-input
          autocomplete="off"
          class="search-item-input mb-2 p-2"
          key="search-input"
          placeholder="Search simulation..."
          size="xs"
          type="text"
          v-model="searchTerm"
        />
      </div>
      <vue-perfect-scrollbar
        v-if="filteredItems"
        class="selection-scroll pr-0 mr-0"
        :settings="{ suppressScrollX: true, wheelPropagation: false }"
      >
        <b-dropdown-item
          v-for="(item, index) in filteredItems"
          :key="`item-${index}`"
          :active="setItemActive(item)"
          link-class="d-flex px-3 py-2"
          @click="onItemClick(item)"
        >
          <b-avatar
            class="align-self-center mr-2 no-border"
            size="2.5em"
            :src="item.avatar ? item.avatar : ''"
          ></b-avatar>
          <div class="align-self-center">
            <p class="mb-0 font-semi-bold">{{ item.client }}</p>
            <span class="font-extra-light">{{ item.name }}</span>
          </div>
          <i
            v-if="setItemActive(item)"
            class="align-self-center fas fa-circle-check ml-auto text-primary"
          ></i>
        </b-dropdown-item>
      </vue-perfect-scrollbar>
    </b-dropdown-group>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingPrompt: {
      type: String,
      default: 'Loading...'
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      searchTerm: ''
    }
  },
  methods: {
    async onItemClick(item) {
      this.selected = item
      this.$emit('changed', item)
    },
    setItemActive(item) {
      return this.selected && this.selected.id === item.id
    }
  },
  computed: {
    filteredItems() {
      return this.items
        .filter((item) => {
          return item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        })
        .sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
    },
    selected: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.selection-scroll {
  max-height: 300px;
  overflow-y: auto;
}
</style>
