<template>
  <div id="app-container" class="app-container rounded">
    <div v-if="currentUser">
      <sidebar :menuItems="filteredMenu" />
      <main class="p-0 mr-0">
        <div class="container-fluid pr-0 pt-4">
          <transition name="zoom" mode="out-in">
            <router-view />
          </transition>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { adminMenuItems } from '@/constants/menu'
import { managementMixin } from '@/mixins/managementMixin'
import Sidebar from '@/containers/Sidebar'
export default {
  components: {
    sidebar: Sidebar
  },
  data() {
    return {
      menuItems: adminMenuItems
    }
  },
  mixins: [managementMixin],
  computed: {
    ...mapGetters(['currentSimulation', 'currentUser']),
    canManageSimulation() {
      return (
        (this.currentSimulation && [2, 3].includes(this.currentSimulation.roleId)) ||
        (this.currentUser && [1, 2].includes(this.currentUser.accountRoleId))
      )
    },
    filteredMenu() {
      return this.canManageSimulation ? this.menuItems : []
    }
  },
  watch: {
    currentSimulation: {
      async handler(newValue, oldValue) {
        if (newValue) {
          await this.getSimulationTeams(newValue.id, oldValue ? newValue.id !== oldValue.id : false)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.2s;
}

.fade-enter-active {
  transition-delay: 0.2s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.zoom-leave-active {
  animation-direction: reverse;
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}
</style>
