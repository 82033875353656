<template>
  <b-row>
    <b-col class="col-md-6">
      <base-selection
        class="team-selection"
        error="Simulation is required"
        itemValue="name"
        label="Simulation Name"
        placeholder="Simulation"
        readonly
        required
        validate
        v-model="selectedSimulation"
        :disabled="disabledSimulations"
        :options="simulations"
        :v="$v.selectedSimulation"
      ></base-selection>
    </b-col>
    <b-col class="col-md-6 pl-0 d-flex">
      <base-selection
        class="team-selection align-self-center"
        error="Simulation role is required"
        itemValue="name"
        label="Simulation Role"
        placeholder="Role"
        readonly
        required
        validate
        v-model="selectedSimulationRole"
        :class="[canRemove ? 'w-95' : 'w-100']"
        :options="simulationRoles"
        :v="$v.selectedSimulationRole"
      ></base-selection>
      <icon-button
        v-if="canRemove"
        class="align-self-center ml-2"
        icon="trash"
        hoverText="Remove"
        variant="danger"
        @click="onSimulationRemove"
      ></icon-button>
    </b-col>
    <b-col v-if="showSimulationTeams" class="col-md-6">
      <base-selection
        class="team-selection mb-0"
        error="Team is required"
        itemValue="name"
        label="Team"
        menuClass="dropdown-menu-lg"
        placeholder="Team"
        readonly
        required
        validate
        v-model="selectedTeam"
        :disabled="loadingSimulationTeams"
        :loading="loadingSimulationTeams"
        :options="sortedSimulationTeams"
        :v="$v.selectedTeam"
      ></base-selection>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { initializeSimulationSelection } from '@/utils/initialization'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import BaseSelection from '@/components/Selections/BaseSelection.vue'
import IconButton from '@/components/Common/IconButton.vue'
export default {
  components: {
    BaseSelection,
    IconButton
  },
  props: {
    canRemove: {
      type: Boolean,
      default: true
    },
    disabledSimulations: {
      type: Boolean,
      default: false
    },
    simulationRoles: {
      type: Array,
      default: () => []
    },
    simulations: {
      type: Array,
      default: () => []
    },
    simulationTeams: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => initializeSimulationSelection()
    }
  },
  data() {
    return {
      filteredSimulationRoles: [],
      isUpdating: false,
      selectedSimulation: null,
      selectedSimulationRole: null,
      selectedTeam: null,
      simulationTeamsData: []
    }
  },
  mixins: [validationMixin],
  validations: {
    selectedSimulation: { required },
    selectedSimulationRole: { required },
    selectedTeam: {
      required: requiredIf(function () {
        // Only require 'selectedTeam' if the user is NOT a simulation master
        return !this.isSimulationMaster
      })
    }
  },
  methods: {
    onSimulationRemove() {
      this.$emit('remove')
      this.resetSimulations()
    },
    async resetSimulations() {
      // clear the selected simulations
      this.selectedSimulation = null
      this.selectedSimulationRole = null
      this.selectedTeam = null
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapGetters('loading', ['loadingSimulationTeams']),
    isSimulationMaster() {
      return this.selectedSimulationRole && this.selectedSimulationRole.id === 3
    },
    showSimulationTeams() {
      return (
        this.selectedSimulationRole &&
        this.selectedSimulationRole &&
        this.selectedSimulationRole.id !== 3
      )
    },
    simulation: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    sortedSimulationTeams() {
      return this.simulationTeamsData.sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  watch: {
    selectedSimulation: {
      handler(newValue) {
        if (newValue) {
          this.simulation.id = newValue.id
          this.simulationTeamsData = _.cloneDeep(
            this.simulationTeams.filter((team) => team.simulationId === newValue.id)
          )
        }
      }
    },
    selectedSimulationRole: {
      handler(newValue) {
        if (newValue) {
          this.simulation.simulationRoleId = newValue.id
        }
      }
    },
    selectedTeam: {
      handler(newValue) {
        if (newValue) {
          this.simulation.simulationTeamId = newValue.id
        }
      }
    },
    simulationRoles: {
      async handler(newValue) {
        if (newValue.length) {
          const simulationRole = await _.cloneDeep(
            newValue.find((r) => r.id === this.simulation.simulationRoleId)
          )

          if (simulationRole) {
            this.selectedSimulationRole = simulationRole
          }
        }
      },
      deep: true,
      immediate: true
    },
    simulations: {
      async handler(newValue) {
        if (newValue.length) {
          const simulation = await _.cloneDeep(newValue.find((s) => s.id === this.simulation.id))
          if (simulation) {
            this.selectedSimulation = simulation
          }
        }
      },
      deep: true,
      immediate: true
    },
    simulationTeams: {
      async handler(newValue) {
        if (newValue.length) {
          const team = await _.cloneDeep(
            newValue.find((t) => t.id === this.simulation.simulationTeamId)
          )

          if (team) {
            this.selectedTeam = team
          }
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
