<template>
  <div class="users pr-4">
    <div class="d-flex pb-3">
      <base-input
        class="search-user-input align-self-center mb-0"
        placeholder="Search user..."
        type="search"
        v-model="searchQuery"
      ></base-input>
      <div class="align-self-center ml-auto">
        <b-button size="sm" variant="danger" class="text-uppercase mr-2" @click="onNewUserClick">
          <span>Add New User</span>
        </b-button>
        <b-button
          v-if="!adminMode"
          class="text-uppercase"
          size="sm"
          variant="outline-danger"
          @click="onUserImportClick"
        >
          <span>Import Users</span>
        </b-button>
      </div>
    </div>

    <b-card class="user-table-card" body-class="p-0">
      <h4 class="p-4 mb-0">Users</h4>
      <action-table
        v-if="sortedUsers"
        :fields="userDataFields"
        :filter="filter"
        :filterOn="filterOnFields"
        :items="sortedUsers"
        :key="reloadUserTable"
        :perPage="10"
        :stickyHeader="false"
        actionTooltip="name"
        class="user-table"
        hasActions
        hasUnblockAction
        tableClass="px-4"
        @itemUnblocked="onUserUnblock"
        @itemClicked="onUserEdit"
        @itemDeleted="onUserDelete"
      ></action-table>
    </b-card>

    <b-modal
      v-model="showUserModal"
      size="lg"
      hide-header
      hide-footer
      content-class="user-edit-modal"
      no-close-on-backdrop
      @hide="onUserCancel"
    >
      <div v-if="!searchedUser && isNewUser && !adminMode">
        <base-user-selection @added="onUserAdd" @cancel="onUserCancel"></base-user-selection>
      </div>
      <user-form
        v-else
        :adminMode="adminMode"
        :editMode="!isNewUser"
        :simulationMode="!adminMode"
        v-model="userData"
        @userSaved="onUserSave"
        @userCancelled="onUserCancel"
      ></user-form>
    </b-modal>

    <!-- Import Users Modal -->
    <b-modal v-model="showUserImportModal" hide-header hide-footer>
      <h3 class="mb-4">Import users</h3>
      <b-form @submit.prevent="onUserImportSubmit" class="av-tooltip tooltip-label-bottom">
        <div class="d-flex flex-column">
          <b-input-group>
            <label class="pl-0 mb-2 col-12">Select user file: </label>
            <b-form-file
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              @change="onFileChange"
            ></b-form-file>
          </b-input-group>

          <div class="ml-auto mt-4">
            <processing-button
              label="Save"
              variant="primary"
              :processing="processingUserImport"
            ></processing-button>
          </div>
        </div>
      </b-form>
    </b-modal>

    <!-- Confirm Action -->
    <action-confirm-modal
      actionButton="Delete"
      actionButtonVariant="danger"
      class="delete-user-modal"
      v-model="showConfirmModal"
      :key="reloadActionTable"
      :processing="processingSimulationUser || processingUser"
      @actionConfirmed="onActionConfirm"
      @actionCanceled="onActionCancel"
    >
      <template v-if="deletedUser" v-slot:body>
        <h6>
          Are you sure you want to delete
          <span class="deleted-user-name">{{ deletedUser.full_name }}</span
          >?
        </h6>
        <p>This action cannot be undone once confirmed.</p>
      </template>
    </action-confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { helperMixin } from '@/mixins/helperMixin'
import {
  initializeUser,
  initializeSimulationUserTableFields,
  initializeUserTableFields
} from '@/utils/initialization'
import { validationMixin } from 'vuelidate'
const { required, email, minLength, sameAs } = require('vuelidate/lib/validators')
import { userFilterFields } from '@/components/Dashboard/config'
import ActionConfirmModal from '@/components/Modals/ActionConfirmModal.vue'
import ActionTable from '@/components/Tables/ActionTable.vue'
import BaseInput from '@/components/Common/BaseInput.vue'
import BaseUserSelection from '@/components/Selections/BaseUserSelection.vue'
import ProcessingButton from '@/components/Common/ProcessingButton.vue'
import ScaleLoader from 'vue-spinner/src/ScaleLoader'
import UserForm from '@/components/Form/UserForm.vue'
export default {
  components: {
    ActionConfirmModal,
    ActionTable,
    BaseInput,
    BaseUserSelection,
    ProcessingButton,
    ScaleLoader,
    UserForm
  },
  props: {
    adminMode: {
      type: Boolean,
      default: false
    }
  },
  mixins: [validationMixin, helperMixin],
  data() {
    return {
      userDataFields: this.adminMode
        ? initializeUserTableFields()
        : initializeSimulationUserTableFields(),
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      filter: null,
      filterOn: [],
      filterOnFields: userFilterFields,
      loader: {
        color: '#2d7281'
      },
      userModal: {
        id: 'userModal',
        title: '',
        content: {
          id: null,
          firstName: null,
          lastName: null,
          email: null,
          avatar: null,
          password: '',
          confirmPassword: '',
          teamId: null,
          role: null
        }
      },
      userImportFile: null,
      userData: initializeUser(),
      userTableData: [],
      originalUserTableData: [],
      avatarUrl: null,
      resetPassword: false,
      isEditUser: true,
      isAdmnin: false,
      serverUrl: config.VUE_APP_SERVER_URL || process.env.VUE_APP_SERVER_URL,
      actionTooltip: '',
      reloadUserTable: 0,
      selectedUserId: null,
      deletedUser: null,
      showConfirmModal: false,
      showUserModal: false,
      showUserImportModal: false,
      searchedUser: null,
      searchQuery: '',
      processing: false,
      reloadActionTable: 0
    }
  },
  validations() {
    if (!this.resetPassword) {
      if (this.isAdmnin) {
        return {
          userModal: {
            content: {
              firstName: {
                required
              },
              lastName: {
                required
              },
              email: {
                required,
                email,
                minLength: minLength(4)
              }
            }
          }
        }
      } else {
        return {
          userModal: {
            content: {
              firstName: {
                required
              },
              lastName: {
                required
              },
              email: {
                required,
                email,
                minLength: minLength(4)
              },
              teamId: {
                required
              },
              role: {
                required
              }
            }
          }
        }
      }
    } else {
      return {
        userModal: {
          content: {
            firstName: {
              required
            },
            lastName: {
              required
            },
            email: {
              required,
              email,
              minLength: minLength(4)
            },
            password: {
              required
            },
            confirmPassword: {
              sameAsPassword: sameAs('password')
            },
            teamId: {
              required
            },
            role: {
              required
            }
          }
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'addUser',
      'deleteSimulationUser',
      'deleteUser',
      'fetchSimulationUsers',
      'fetchUsers',
      'submitTeamUsersFile',
      'unblockUser'
    ]),
    getServerAvatarUrl(serverUrl, userName) {
      return `${serverUrl}/storage/${userName.toLowerCase()}.png`
    },
    async getUsers(adminMode = false) {
      if (adminMode) {
        const filters = {
          search: ''
        }

        await this.fetchUsers(filters)
        this.userTableData = await _.cloneDeep(this.allUsers)
      } else {
        if (this.currentSimulation) {
          await this.fetchSimulationUsers(this.currentSimulation.id)
          this.userTableData = await _.cloneDeep(this.simulationUsers)
        }
      }

      this.originalUserTableData = await _.cloneDeep(this.userTableData)
    },
    onActionCancel() {
      this.triggerModal(false)
    },
    async onActionConfirm() {
      let user = this.deletedUser
      if (this.adminMode) {
        await this.deleteUser({ id: user.id, name: user.name })
      } else {
        await this.deleteSimulationUser({ id: this.currentSimulation.id, userId: user.id })
      }
      this.triggerModal(false)
      this.reloadUsers()
    },
    onAvatarFileChange(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      const file = files[0]
      this.userModal.content.avatar = file
      this.avatarUrl = URL.createObjectURL(file)
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      const file = files[0]
      this.userImportFile = file
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onNewUserClick() {
      this.userData = initializeUser()

      // set default account role to user if admin mode is false
      if (!this.adminMode) {
        this.userData.accountRoleId = 3
      }

      this.triggerUserModal(true)
    },
    onUserAdd(payload) {
      if (payload.isNew) {
        this.searchedUser = payload.user
        this.userData = payload.user

        // set default account role to user if admin mode is false
        if (!this.adminMode) {
          this.userData.accountRoleId = 3
        }
      } else {
        this.onUserCancel()
        this.reloadUsers()
      }
    },
    onUserCancel() {
      this.triggerUserModal(false)
      this.userData = initializeUser()
      this.searchedUser = null
    },
    onUserDelete(item) {
      this.deletedUser = item
      this.triggerModal(true)
    },
    onUserEdit(item) {
      this.userData = _.cloneDeep(item)
      this.triggerUserModal(true)
    },
    onUserImportClick() {
      this.triggerUserImportModal(true)
    },
    async onUserImportSubmit() {
      const formData = new FormData()
      const userFile = this.userImportFile
      formData.append('file', userFile)
      formData.append('simulation_id', this.currentSimulation.id)

      await this.submitTeamUsersFile(formData)
      await this.triggerUserImportModal(false)
      await this.getUsers(this.adminMode)
    },
    async onUserSave() {
      await this.triggerUserModal(false)
      await this.getUsers(this.adminMode)
      this.reloadActionTable += 1
    },
    async onUserUnblock(item) {
      this.selectedUserId = item.id
      let userForm = new FormData()
      userForm.append('id', this.selectedUserId)
      userForm.append('first_name', item.first_name)
      userForm.append('last_name', item.last_name)
      await this.unblockUser(userForm)
      item.active = 1
    },
    async reloadUsers() {
      await this.getUsers(this.adminMode)
      this.reloadUserTable += 1
    },
    setTooltipText(item) {
      if (this.actionTooltip && _.has(item, this.actionTooltip)) {
        return item[this.actionTooltip]
      } else {
        return this.actionTooltip
      }
    },
    async triggerModal(value) {
      this.showConfirmModal = value
    },
    async triggerUserModal(value) {
      this.showUserModal = value
    },
    async triggerUserImportModal(value) {
      this.showUserImportModal = value
    }
  },
  computed: {
    ...mapGetters(['allTeams', 'allUsers', 'currentSimulation', 'simulationUsers']),
    ...mapGetters('loading', [
      'loadingUsers',
      'processingSimulationUser',
      'processingUser',
      'processingUserImport'
    ]),
    isNewUser() {
      return !this.userData.id
    },
    sortedUsers() {
      return this.userTableData.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
    }
  },
  watch: {
    currentSimulation: {
      async handler(newVal) {
        if (newVal) {
          await this.getUsers(this.adminMode)
        }
      },
      immediate: true
    },
    searchQuery: {
      async handler(newVal) {
        const users = this.originalUserTableData
        if (newVal) {
          const filteredUsers = users.filter((user) => {
            return (
              user.name.toLowerCase().includes(newVal.toLowerCase()) ||
              user.email.toLowerCase().includes(newVal.toLowerCase())
            )
          })
          this.userTableData = _.cloneDeep(filteredUsers)
        } else {
          this.userTableData = await _.cloneDeep(users)
        }
      },
      immediate: true
    }
  },
  created() {
    if (this.adminMode) {
      this.getUsers(this.adminMode)
    }
  }
}
</script>
