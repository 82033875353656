<template>
  <div>
    <b-table
      :class="`action-table ${tableClass}`"
      :current-page="currentPage"
      :fields="allFields"
      :filter="filter"
      :filter-function="filterTable"
      :filter-included-fields="filterOn"
      :items="items"
      :per-page="perPage"
      :sticky-header="stickyHeader"
      hover
      responsive
      table-class="text-nowrap performance-table"
      tbody-tr-class="performance-table-row"
      @filtered="onFiltered"
    >
      <template #thead-top v-if="fieldGroup.length > 0">
        <b-tr>
          <b-th
            v-for="(th, index) in fieldGroup"
            :key="`th-${index}`"
            :colspan="th.items"
            :class="{ 'th-bg-theme-secondary': th.show }"
          >
            <span :class="{ 'sr-only': !th.show }">{{ th.title }}</span>
          </b-th>
        </b-tr>
      </template>
      <template v-if="hasActions" #cell(actions)="row" thClass="w-10 float-left">
        <i
          v-if="hasUnblockAction && row.item.active != 1"
          class="fal fa-user-lock text-danger font-weight-bold"
          @click="onItemUnblocked(row.item)"
          v-tooltip="{
            content: `Unblock ${setTooltipText(row.item)}`,
            placement: 'bottom-center',
            classes: ['light'],
            delay: {
              show: 500,
              hide: 300
            },
            offset: '5'
          }"
        ></i>
        <i
          class="fal fa-user-check text-success"
          v-else="hasUnblockAction && row.item.active == 1"
        ></i>
        <i
          class="fal fa-pen-to-square ml-2 text-primary font-weight-bold"
          @click="onItemClick(row.item)"
          v-tooltip="{
            content: `Edit ${setTooltipText(row.item)}`,
            placement: 'bottom-center',
            classes: ['light'],
            delay: {
              show: 500,
              hide: 300
            },
            offset: '5'
          }"
        ></i>
        <i
          class="fal fa-trash ml-2 text-danger"
          @click="onItemDelete(row.item)"
          v-tooltip="{
            content: `Delete ${setTooltipText(row.item)}`,
            placement: 'bottom-center',
            classes: ['danger'],
            delay: {
              show: 500,
              hide: 300
            },
            offset: '5'
          }"
        ></i>
      </template>
      <template #cell(avatar)="data">
        <b-avatar
          size="2em"
          :src="data.item.avatar"
          :text="getNameInitials(data.item.full_name || data.item.name)"
        ></b-avatar>
        <span class="ml-2">{{ data.item.full_name || data.item.name }}</span>
      </template>
      <template v-if="rowGroup" slot="top-row" slot-scope="{ columns }">
        <td :colspan="columns">{{ items[0][rowGroup] }}</td>
      </template>
      <template v-if="rowGroup && nextRowGroup" slot="row-details" slot-scope="{ item }">
        {{ item[nextRowGroup] }}
      </template>
    </b-table>
    <b-row v-if="hasItem">
      <b-col sm="7" md="6" class="my-1 mx-auto">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          @change="onPageChange"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { helperMixin } from '@/mixins/helperMixin'
export default {
  name: 'action-table',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    fieldGroup: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    rowGroup: {
      type: String,
      default: ''
    },
    nextRowGroup: {
      type: String,
      default: ''
    },
    filter: {
      type: String,
      default: ''
    },
    filterOn: {
      type: Array,
      default: () => []
    },
    hasActions: {
      type: Boolean,
      default: false
    },
    hasUnblockAction: {
      type: Boolean,
      default: false
    },
    actionTooltip: {
      type: String,
      default: ''
    },
    showAvatar: {
      type: Boolean,
      default: false
    },
    perPage: {
      type: Number,
      default: 5
    },
    stickyHeader: {
      type: Boolean,
      default: true
    },
    tableClass: {
      type: String,
      default: ''
    }
  },
  mixins: [helperMixin],
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      loadingPage: false
    }
  },
  methods: {
    onItemClick(item) {
      this.$emit('itemClicked', item)
    },
    onItemDelete(item) {
      this.$emit('itemDeleted', item)
    },
    onItemUnblocked(item) {
      this.$emit('itemUnblocked', item)
    },
    setTooltipText(item) {
      if (this.actionTooltip && _.has(item, this.actionTooltip)) {
        return item[this.actionTooltip]
      } else {
        return this.actionTooltip
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onPageChange() {
      this.loadingPage = true
      setTimeout(() => {
        this.loadingPage = false
      }, 1500)
    },
    filterTable(row, filter) {
      let res = false
      filter = filter.toLowerCase()
      if ('name' in row) {
        res = row.name.toLowerCase().includes(filter)
      }
      if ('first_name' in row && 'last_name' in row) {
        res =
          res ||
          row.first_name.toLowerCase().includes(filter) ||
          row.last_name.toLowerCase().includes(filter) ||
          (row.first_name + ' ' + row.last_name).toLowerCase().includes(filter)
      }
      if (this.filterOn.length > 0) {
        res =
          res ||
          !!this.filterOn.find((ele) => {
            return row[ele].toLowerCase().includes(filter)
          })
      }
      return res
    }
  },
  computed: {
    allFields() {
      let fields = this.fields
      if (
        !fields.find((field) => {
          return field.key === 'actions'
        }) &&
        this.hasActions
      ) {
        fields.push({
          key: 'actions',
          label: 'Actions'
        })
      }

      return fields
    },
    hasItem() {
      return this.items.length > 0
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  watch: {
    items(newValue) {
      this.totalRows = newValue.length
    }
  }
}
</script>
