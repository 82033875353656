import { mapActions, mapGetters } from 'vuex'
import { errorMessages } from '@/utils/validators'
import BaseEntitySelection from '@/components/Selections/BaseEntitySelection.vue'
import BaseInput from '@/components/Common/BaseInput.vue'
import BaseSelection from '@/components/Selections/BaseSelection.vue'
import ProcessingButton from '@/components/Common/ProcessingButton.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import 'nprogress/nprogress.css'
import NProgress from 'nprogress'
export const managementMixin = {
  components: {
    BaseEntitySelection,
    BaseInput,
    BaseSelection,
    ProcessingButton,
    PulseLoader
  },
  props: {
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      errorMessages,
      formChanged: false,
      loading: false,
      processing: false,
      teams: []
    }
  },
  methods: {
    ...mapActions([
      'fetchAllTeams',
      'fetchAuditLogFilters',
      'fetchSimulationTeams',
      'fetchSimulationUsers'
    ]),
    async getSimulationTeams(refresh = false) {
      if (!this.hasSimulationTeams || refresh) {
        await this.fetchSimulationTeams(this.currentSimulation.id)
      }
    },
    async getSimulationUsers(refresh = false) {
      if (!this.hasSimulationUsers || refresh) {
        await this.fetchSimulationUsers(this.currentSimulation.id)
      }
    },
    async getAllTeams() {
      await this.fetchAllTeams()
    },
    setError(item, name) {
      if (item.$dirty) {
        let errorMessage = ''
        let errorMessages = this.errorMessages
        if (item.required === false) {
          errorMessage = `${name} ${errorMessages.required}`
          return errorMessage
        }

        if (item.email === false) {
          errorMessage = `${name} ${errorMessages.email}`
          return errorMessage
        }

        if (item.emailMinLength === false) {
          errorMessage = `${name} ${errorMessages.emailMinLength}`
          return errorMessage
        }

        if (item.passwordMaxLength === false) {
          errorMessage = `${name} ${errorMessages.passwordMaxLength}`
          return errorMessage
        }

        if (item.passwordMinLength === false) {
          errorMessage = `${name} ${errorMessages.passwordMinLength}`
          return errorMessage
        }

        if (item.isPasswordValid === false) {
          errorMessage = `${errorMessages.isPasswordValid}`
          return errorMessage
        }

        if (item.samePassword === false) {
          errorMessage = `${name} ${errorMessages.samePassword}`
          return errorMessage
        }
      }
    },
    async onItemSave(action, actionItem, successMessage) {
      await this.$store
        .dispatch(action, actionItem)
        .then((response) => {
          this.processing = false
          this.formChanged = false
          this.$notify(response.status, successMessage, response.message, {
            duration: 2000,
            permanent: false
          })
          this.$emit('itemSaved', true)
        })
        .catch((error) => {
          this.processing = false
          this.$notify('error', error, null, {
            duration: 3000,
            permanent: false
          })
          NProgress.done()
        })
    },
    onCancelClick() {
      this.$emit('itemCancelled')
    }
  },
  computed: {
    ...mapGetters(['currentSimulation', 'simulationTeams', 'simulationUsers']),
    ...mapGetters('loading', [
      'loadingAuditLogFilters',
      'loadingSimulationTeams',
      'loadingSimulationUsers'
    ]),
    hasSimulationTeams() {
      return this.simulationTeams.length > 0
    },
    hasSimulationUsers() {
      return this.simulationUsers.length > 0
    }
  }
}
