<template>
  <b-form class="base-user-selection d-flex flex-column" @submit.prevent="onUserSubmit">
    <base-input
      :placeholder="`Search user...`"
      class="align-self-center w-100 mb-0"
      icon="magnifying-glass"
      label="Name or Email Address"
      required
      type="email"
      v-model="userSearchQuery"
      validate
      :v="$v.userSearchQuery"
      :error="setError($v.userSearchQuery, 'Email')"
    ></base-input>
    <p v-if="foundUser" class="mt-2 mb-0">
      <i class="fas fa-circle-check text-success mx-1"></i>
      <span v-if="foundSimulationUser" class="font-light">
        This user already has a simulation account in {{ currentSimulation.name }}.
      </span>
      <span v-else class="font-light">
        This user already has an account, click add to invite them to
        {{ currentSimulation.name }}.
      </span>
    </p>
    <p v-if="foundNoUserMatched" class="mt-2">
      <i class="fas fa-circle-xmark text-danger mx-1"></i>
      <span vclass="font-light">
        This user doesn't have a simulation account, click next to create an account for them.
      </span>
    </p>
    <div class="d-flex flex-column">
      <b-card v-if="hasUsers && noUserSelected && !foundSimulationUser" body-class="p-0">
        <div class="d-flex mx-4 my-2 w-100">
          <div class="w-40 font-extra-light">
            <span>Name</span>
          </div>
          <div class="w-60 font-extra-light">
            <span>Email</span>
          </div>
        </div>
        <vue-perfect-scrollbar
          v-if="sortedUsers"
          class="selection-scroll mr-0"
          :settings="{ suppressScrollX: true, wheelPropagation: false }"
        >
          <div
            v-for="(user, index) in sortedUsers"
            :key="`user-${index}`"
            class="d-flex dropdown-item user-item px-4 py-3"
            @click="onUserSelect(user)"
          >
            <div class="w-40">
              <span>{{ user.name }}</span>
            </div>
            <div class="w-60">
              <span>{{ user.email }}</span>
            </div>
          </div>
        </vue-perfect-scrollbar>
      </b-card>
      <base-selection
        v-if="(hasUsers && !noUserSelected && !isUserSearchInvalid) || foundNoUserMatched"
        class="team-selection my-3 animate__animated andimate__fadeIn"
        itemValue="name"
        label="Simulation Role"
        menuClass="dropdown-menu-xl"
        placeholder="Role"
        readonly
        required
        validate
        v-model="selectedSimulationRole"
        :error="setError($v.selectedSimulationRole, 'Role')"
        :options="simulationRoles"
        :v="$v.selectedSimulationRole"
      ></base-selection>
      <base-selection
        v-if="(!noUserSelected || foundNoUserMatched) && selectedSimulationRole && !isGameMaster"
        class="team-selection animate__animated andimate__fadeIn"
        error="Team is required"
        itemValue="name"
        label="Team"
        menuClass="dropdown-menu-xl"
        placeholder="Team"
        readonly
        required
        validate
        v-model="selectedSimulationTeam"
        :disabled="loadingSimulationTeams"
        :loading="loadingSimulationTeams"
        :options="sortedSimulationTeams"
        :v="$v.selectedSimulationTeam"
      ></base-selection>
    </div>
    <div class="d-flex align-self-center ml-auto mt-4">
      <b-button class="align-self-center" variant="danger" @click="onUserCancel">
        <span>Cancel</span>
      </b-button>
      <processing-button
        v-if="(hasUsers || foundNoUserMatched) && !foundSimulationUser"
        class="align-self-center ml-2"
        variant="primary"
        :label="processingButtonLabel"
        :processing="processingSimulationUser"
      ></processing-button>
    </div>
  </b-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { debounce } from 'lodash'
import { errorMessages } from '@/utils/validators'
import { initializeUser } from '@/utils/initialization'
import { required, requiredIf, email, minLength, maxLength } from 'vuelidate/lib/validators'
import { SEARCH_INPUT_DEBOUNCE } from '@/constants/config'
import { validationMixin } from 'vuelidate'
import BaseInput from '@/components/Common/BaseInput.vue'
import BaseSelection from '@/components/Selections/BaseSelection.vue'
import ProcessingButton from '@/components/Common/ProcessingButton.vue'
export default {
  components: {
    BaseInput,
    BaseSelection,
    ProcessingButton
  },
  data() {
    return {
      errorMessages,
      selectedSimulationRole: null,
      selectedSimulationTeam: null,
      selectedUser: initializeUser(),
      unwatchUserSearchQuery: null,
      users: [],
      userSearchQuery: ''
    }
  },
  mixins: [validationMixin],
  validations: {
    selectedSimulationRole: {
      required
    },
    selectedSimulationTeam: {
      required: requiredIf(function () {
        // Only require 'selectedTeam' if the user is NOT a simulation master
        return !this.isGameMaster
      })
    },
    userSearchQuery: {
      required,
      email,
      minLength: minLength(3),
      maxLength: maxLength(255)
    }
  },
  methods: {
    ...mapActions(['fetchUsers', 'upsertSimulationUsers']),
    async getUsers() {
      if (this.loadingUsers) return

      const filters = {
        search: this.userSearchQuery,
        simulationId: this.currentSimulation?.id ?? null
      }

      await this.fetchUsers(filters)
      this.users = await _.cloneDeep(this.allUsers)
    },
    initUserSearchWatcher() {
      if (this.unwatchUserSearchQuery) {
        this.unwatchUserSearchQuery() // Stop the watcher
      }

      this.unwatchUserSearchQuery = this.$watch(
        'userSearchQuery',
        debounce(async function (newValue) {
          this.selectedUser = initializeUser()
          this.selectedSimulationTeam = null
          this.selectedSimulationRole = null
          if (newValue.length >= 3) {
            await this.getUsers()
          }
        }, SEARCH_INPUT_DEBOUNCE)
      )
    },
    async onUserSubmit() {
      if (this.foundUser) {
        // validate before submit
        this.$v.$touch()
        if (this.$v.$invalid) return

        // add the user to the simulation
        let payload = {
          user_id: this.selectedUser.id,
          role: this.selectedSimulationRole.id
        }

        if (this.selectedSimulationTeam) {
          payload = {
            ...payload,
            team_id: this.selectedSimulationTeam.id
          }
        }
        await this.upsertSimulationUsers({ id: this.currentSimulation.id, payload })
        this.$emit('added', { user: this.selectedUser, isNew: false })
      } else {
        // validate before submit
        this.$v.$touch()
        if (this.$v.$invalid) return

        let simulation = {
          id: this.currentSimulation.id,
          simulationRoleId: this.selectedSimulationRole.id
        }

        if (this.selectedSimulationTeam) {
          simulation = {
            ...simulation,
            simulationTeamId: this.selectedSimulationTeam.id
          }
        }

        const simulations = [simulation]
        this.selectedUser.simulations = simulations
        this.selectedUser.email = this.userSearchQuery
        this.$emit('added', { user: this.selectedUser, isNew: true })
      }

      this.selectedUser = initializeUser()
      this.selectedSimulationTeam = null
      this.selectedSimulationRole = null
      this.userSearchQuery = ''
    },
    onUserCancel() {
      this.$emit('cancel')
    },
    async onUserSelect(user) {
      if (this.unwatchUserSearchQuery) {
        this.unwatchUserSearchQuery() // Stop the watcher
      }

      this.userSearchQuery = user.email
      this.selectedUser = user

      this.$nextTick(() => {
        this.initUserSearchWatcher()
      })
    },
    setError(item, name) {
      if (item.$dirty) {
        let errorMessage = ''
        let errorMessages = this.errorMessages
        if (item.required === false) {
          errorMessage = `${name} ${errorMessages.required}`
          return errorMessage
        }

        if (item.email === false) {
          errorMessage = `${errorMessages.email}`
          return errorMessage
        }

        if (item.emailMinLength === false) {
          errorMessage = `${errorMessages.emailMinLength}`
          return errorMessage
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'allUsers',
      'currentSimulation',
      'simulationRoles',
      'simulationTeams',
      'simulationUsers'
    ]),
    ...mapGetters('loading', [
      'loadingSimulationTeams',
      'loadingUsers',
      'processingSimulationUser'
    ]),
    filteredUsers() {
      // exclude simulationUsers from the list
      return this.users.filter((user) => {
        return !this.simulationUsers.some((simulationUser) => simulationUser.id === user.id)
      })
    },
    foundNoUserMatched() {
      return !this.hasUsers && !this.isUserSearchInvalid
    },
    foundSimulationUser() {
      return this.simulationUsers.find((user) => user.email === this.userSearchQuery)
    },
    foundUser() {
      return this.users.find((user) => user.email === this.userSearchQuery)
    },
    hasUsers() {
      return this.users.length > 0
    },
    isGameMaster() {
      return this.selectedSimulationRole && this.selectedSimulationRole.level === 1
    },
    isUserSearchInvalid() {
      return this.$v.userSearchQuery.$invalid
    },
    noUserSelected() {
      return this.selectedUser && this.selectedUser.id === null
    },
    processingButtonLabel() {
      if (this.hasUsers) {
        return 'Add'
      }

      if (this.foundNoUserMatched) {
        return 'Next'
      }

      return 'Submit'
    },
    sortedSimulationTeams() {
      // filter teams in current simulation
      const simulationTeams = _.cloneDeep(this.simulationTeams)
      return simulationTeams
        .filter((team) => team.simulationId === this.currentSimulation.id)
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    sortedUsers() {
      return this.filteredUsers.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
    }
  },
  mounted() {
    this.initUserSearchWatcher()
  }
}
</script>

<style lang="scss" scoped>
.selection-scroll {
  max-height: 300px;
  overflow-y: auto;
}
</style>
