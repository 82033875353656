var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon-button"},[_c('i',_vm._g({directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.hoverText ? _vm.hoverText : '',
      placement: 'top-center',
      classes: [("" + _vm.tooltipClass)]
    }),expression:"{\n      content: hoverText ? hoverText : '',\n      placement: 'top-center',\n      classes: [`${tooltipClass}`]\n    }"}],class:[
      (_vm.hovered || _vm.clicked) && !_vm.disabled ? ("fas text-" + _vm.variant) : ("" + _vm.defaultIconStyle),
      { 'text-muted': _vm.disabled },
      ("fa-" + _vm.icon),
      ("fa-" + _vm.size),
      _vm.iconClass
    ],attrs:{"id":("icon-button-" + _vm.id)},on:{"mouseover":_vm.onMouseover,"mouseleave":_vm.onMouseleave}},_vm.disabled ? {} : { click: _vm.onIconButtonClick }))])}
var staticRenderFns = []

export { render, staticRenderFns }