<template>
  <div class="simulation-selection-fields-list">
    <div v-for="(item, index) in selectedSimulations" :key="`sim-fields-item-${index}`">
      <simulation-selection-fields
        :canRemove="canRemove"
        :disabledSimulations="simulationMode"
        :ref="`sim-fields-item-${item.id}`"
        :simulationRoles="filterSimulationRoles(selectedSimulations[index])"
        :simulations="simulations"
        :simulationTeams="simulationTeams"
        v-model="selectedSimulations[index]"
        @remove="selectedSimulations.splice(index, 1)"
      >
      </simulation-selection-fields>
      <hr
        v-if="selectedSimulations.length && index !== selectedSimulations.length - 1"
        class="my-4"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { helperMixin } from '@/mixins/helperMixin'
import SimulationSelectionFields from './SimulationSelectionFields.vue'
export default {
  components: {
    SimulationSelectionFields
  },
  props: {
    canRemove: {
      type: Boolean,
      default: true
    },
    simulationMode: {
      type: Boolean,
      default: false
    },
    simulationRoles: {
      type: Array,
      default: () => []
    },
    simulations: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  mixins: [helperMixin],
  methods: {
    ...mapActions(['fetchSimulationTeams']),
    filterSimulationRoles(simulation) {
      // admin user to return all simulation roles
      if (this.canManageGlobalUsers) {
        return this.simulationRoles
      } else {
        // Filter the simulation roles based on the selected simulation role
        const userSimulation = this.currentUser.simulations.find((sim) => sim.id === simulation.id)

        if (userSimulation) {
          const userSimulationRole = this.simulationRoles.find(
            (role) => role.id === userSimulation.simulationRoleId
          )
          return this.simulationRoles.filter((role) => role.level >= userSimulationRole.level)
        }
      }
    },
    validateSimulationSelections() {
      let result = true
      this.selectedSimulations.forEach((simulation) => {
        this.$refs[`sim-fields-item-${simulation.id}`][0].$v.$touch()
        if (this.$refs[`sim-fields-item-${simulation.id}`][0].$v.$invalid) {
          result = false
        }
      })

      return result
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'simulationTeams']),
    selectedSimulations: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    simulations: {
      async handler(newValue) {
        if (newValue.length) {
          // Get a list of unique simulation IDs
          const simulationIds = newValue
            .map((sim) => sim.id)
            .filter((id) => id !== null && id !== undefined)
          await this.fetchSimulationTeams(simulationIds)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
