<template>
  <div class="icon-button">
    <i
      :class="[
        (hovered || clicked) && !disabled ? `fas text-${variant}` : `${defaultIconStyle}`,
        { 'text-muted': disabled },
        `fa-${icon}`,
        `fa-${size}`,
        iconClass
      ]"
      :id="`icon-button-${id}`"
      @mouseover="onMouseover"
      @mouseleave="onMouseleave"
      v-on="disabled ? {} : { click: onIconButtonClick }"
      v-tooltip="{
        content: hoverText ? hoverText : '',
        placement: 'top-center',
        classes: [`${tooltipClass}`]
      }"
    ></i>
  </div>
</template>

<script>
export default {
  props: {
    clicked: {
      type: Boolean,
      default: false
    },
    defaultIconStyle: {
      type: String,
      default: 'fal text-muted'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'note'
    },
    iconClass: {
      type: String,
      default: ''
    },
    id: {
      type: [Number, String],
      default: 0
    },
    hoverText: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'md'
    },
    tooltipClass: {
      type: String,
      default: 'light'
    },
    variant: {
      type: String,
      default: 'primary'
    }
  },
  data() {
    return {
      hovered: false
    }
  },
  methods: {
    onIconButtonClick() {
      this.$emit('click')
    },
    onMouseleave() {
      this.hovered = false
    },
    onMouseover() {
      this.hovered = true
    }
  },
  computed: {
    iconClicked: {
      get() {
        return this.clicked
      },
      set(value) {
        this.$emit('update:clicked', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-button {
  &:hover {
    cursor: pointer;
  }
}
</style>
